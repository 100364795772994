import styled from 'styled-components';
import { slideInBottom } from '../../../style/animations';
import { useTranslation } from 'react-i18next';
import { activeItemIdAtom } from '../../../atoms/scene';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import PopoverModal from '../../containers/PopoverModal';
import { useEffect, useState } from 'react';
import { GemDirectQueueAtom, GemEventQueueEntry, GemQueueAtom, handleGemQueueAtom } from '../../../atoms/gem';
import { enableFifoAtom } from '../../../atoms/fifo';
import Markdown from 'react-markdown';
import { scenarioDataAtom } from '../../../atoms/content';

const InteractiveItem = () => {

	const { t } = useTranslation();

	const [activeItemId, setActiveItemDId] = useAtom(activeItemIdAtom);
	const scenarioData = useAtomValue(scenarioDataAtom);
	const [, setGemDirectQueue] = useAtom(GemDirectQueueAtom);
	const [, GemQueueDispatch] = useAtom(GemQueueAtom);
	const [useFifo, setUseFifo] = useState<boolean>(false);

	useEffect(() => {
		if (!scenarioData || !activeItemId) return;
		setGemDirectQueue(scenarioData.items[activeItemId].events.filter(event => event.direct === true));
		processEvents(scenarioData.items[activeItemId].events.filter(event => !event.direct));
	}, [activeItemId]);

	const processEvents = async (events: GemEventQueueEntry[]) => {
		events.map((event) => {//for every action in the data
			if (event.fifo) setUseFifo(true);
			GemQueueDispatch({ type: 'add', payload: event });
		});
	};

	const setHandleGemQueue = useSetAtom(handleGemQueueAtom);
	const setEnableFifo = useSetAtom(enableFifoAtom);

	/**
	 * Called when closing the interactive item
	  */
	const onClose = () => {
		setActiveItemDId(undefined);
		// FIFO or direct handling queue
		useFifo ? setEnableFifo(true) : setHandleGemQueue(true);
		setUseFifo(false);
	};


	return (
		<>
			{activeItemId &&
				<PopoverModal
					title={t(`item.${activeItemId}.title`)}
					onClose={onClose}
					containerchildren={
						<Image src={scenarioData ? scenarioData.items[activeItemId].image : ''} />
					}
					style={{ height: 'fit-content' }}
				>
					<StyledReactMarkdown>{t(`item.${activeItemId}.description`)}</StyledReactMarkdown>
				</PopoverModal>
			}
		</>
	);
};

const StyledReactMarkdown = styled(Markdown)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 20px;

	& > p {
		margin: 0;
	}

	& > ul > li {
		text-align: start;
	}
`;


const Image = styled.div<{ src: string }>`

	position: relative; 

    background-image: url(${p => p.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

	width: 100%;
	flex-grow: 1;

	translate: 0 1000%;
	animation: ${slideInBottom} 1s forwards;
	animation-delay: 1s;

	max-width: 900px;
	margin-inline: auto;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		background-size: 90%;
	}
`;

export default InteractiveItem;
