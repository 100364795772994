import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';


const scenes_scenario_79: { [key: string]: SceneData } = {
    'essity_1': {
        camera_settings: {
            position: new Vector3(-8.2, 1.2, 5.95),
            target: new Vector3(-6.6, 1, 6)

        },

        objects: {
            '5':{
                type: 'character',
                position: new Vector3(-6.6, -0.45, 6),
                rotation: [0, -1.6, 0],
                sitting: true,
                idle: 'idle_sitting_desk',
            }
  
        },
    },

};

export default scenes_scenario_79;