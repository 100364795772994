import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import styled from 'styled-components';
import { fadeIn } from '../../../../style/animations';
import { useAtomValue, useSetAtom } from 'jotai';
import { messageTimerAtom } from '../../../../atoms/messaging';
import { scenarioDataAtom } from '../../../../atoms/content';

// data

let totalTime = 12;
const defaultTime = new Date();
defaultTime.setSeconds(defaultTime.getSeconds() + 9999999);

const Timer = (props: TimerComponentProps) => {

	const scenarioData = useAtomValue(scenarioDataAtom);

	useEffect(() => {
		if (!scenarioData) return;
		if (!scenarioData.custom_data) return;
		totalTime = scenarioData.custom_data.overwrite_timer_time ?? 12;
	}, []);

	const onTimerEnd = () => {
		props.onTimerEnd();
	};

	const timer = useTimer({ expiryTimestamp: defaultTime, onExpire: onTimerEnd, autoStart: false });
	const setTimerAtom = useSetAtom(messageTimerAtom);

	const [initialized, setInitialized] = useState<boolean>(false);

	const initializeTimer = () => {
		const time = new Date();
		time.setSeconds(time.getSeconds() + totalTime);
		timer.restart(time);
		!initialized && setInitialized(true);
	};



	useEffect(() => {
		timer && setTimerAtom(timer);
	}, []);

	return (
		<_Timer
			$time={!initialized ? totalTime : timer.seconds}
			$totaltime={totalTime}
			onAnimationEnd={() => initializeTimer()}
			$isrunning={timer.isRunning}
		>
			{initialized ? timer.seconds : totalTime}
		</_Timer>
	);
};

const _Timer = styled.div.attrs<TimerProps>(({ $time, $totaltime }) => ({ $timeinpercentage: $time / $totaltime * 100 })
) <TimerProps>`

    border-radius: 100%;
	font-size: 2em;
	font-weight: 700;
	padding: .1em;
	background-color: ${p => p.theme.colors.neutralDarkest};
    display: flex;
	justify-content: center;
	align-items: center;
	color: ${p => p.theme.colors.primary};

	filter: grayscale(${p => !p.$isrunning ? '100%' : '0%'});

    & > div {
		position: absolute;
   	 	color: ${p => p.theme.colors.primary};
	}
	
    &:before {
        content: '';
		z-index: -1;
        position: absolute;
        height: calc(100% + .2em); width: calc(100% + .2em);
        top: -.1em; left: -.1em;
        background: 
        radial-gradient(closest-side, ${p => p.theme.colors.neutralLightest} 90%, transparent 50% 100%),
        conic-gradient(${p => p.$timeinpercentage !== undefined && p.theme.colors.primary} ${p => p.$timeinpercentage}%, ${p => p.theme.colors.neutralLightest} 0);

        border-radius: 100%;
    }
	opacity: 0;

	animation: ${fadeIn} 1s forwards;
	animation-delay: 1s;

	transition: filter .5s;

`;

// types

type TimerProps = { $time: number, $totaltime: number, $timeinpercentage?: number, $isrunning: boolean };
type TimerComponentProps = {
	onTimerEnd: () => void
};



export default Timer;
