import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_76: { [key: string]: SceneData } = {
	'dwp_s1' : {		
		camera_settings: {
			position: new Vector3(0.6951935442588593, 1.5716814036539848, -6.199526797580308),
			target: new Vector3(0, 1.6, -5),
		},
		
		objects: {
			'12': {	
				type : 'character', 
				props: true,
				position: new Vector3(0.8,0,-3.5),
				rotation: [0,Math.PI+0.4,0],
				startMessages: ['3894',''],
			},

		},

		background_characters: [
			{	
				position: new Vector3(1.5, 0, 3),
				rotation: new Euler(0,1,0),
				model: 'morgan',
				anim: 'idle_01'
			},
			{	
				position: new Vector3(2.5, 0, 2.7),
				rotation: new Euler(0,-1.5,0),
				model: 'yara',
				anim: 'talking_01'
			}
		]
	},

};
export default scenes_scenario_76;