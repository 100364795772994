import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const interrol_scenes: { [key: string]: SceneData } = {
	'scene1': {
		camera_settings: {
			position: new Vector3(0, 2, 2),
			target: new Vector3(0, 1.2, 0),
		},
		objects: {
			'22': {
				type: 'character',
				position: new Vector3(0, 0, 0),
			},
		},
	},
	'scene2': {
		camera_settings: {
			position: new Vector3(0.4, 1.5, 0),
			target: new Vector3(2, 1.2, 0),
		},
		objects: {
			'22': {
				type: 'character',
				position: new Vector3(2, 0, 0),
				rotation: [0, Math.PI + 1.5, 0],
				startMessages: ['3917'],
			},
			'conveyor': {
				type: 'item',
				position: new Vector3(3.1, 1, 0),
				item_id: '9',
				mesh: 'kopje',
				indicatorScale: 0.2,
				indicatorOffset: {
					x: 0,
					y: 0.5,
					z: 1.7,
				},
			}
		},
	}

};

export default interrol_scenes;
